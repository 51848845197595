const MwaAdvantages = () => {
  return (
    <div data-section id="advantages" className="bg-stone-100">
      <div className="grid lg:grid-cols-2 gap-4">
        <div className="p-10 lg:w-3/4 lg:order-1 order-2 m-auto">
          <img src="./img/banner-advantages.png" alt="banner" />
        </div>
        <div className="h-full lg:order-2 order-1 flex flex-col justify-center items-center p-10">
          <h2 className="text-primary text-4xl font-bold">
            Tham gia SÀN VẬN TẢI có lợi ích gì?
          </h2>
          <img
            src="./img/truck-line-break.png"
            className="w-fit py-4"
            alt="img"
          />
          <div className="flex self-start">
            <img
              src="./img/advantages-icon2.png"
              className="w-14 h-fit mr-4"
              alt="img"
            />
            <div>
              <h5 className="text-primary font-bold mb-2">
                CHỦ XE TÌM HÀNG NHANH CHÓNG
              </h5>
              <li>Tìm hàng ghép - hàng quay đầu nhanh chóng</li>
              <li>Có tất cả các tuyến toàn quốc để lựa chọn</li>
              <li>Tiếp cận hàng ngàn đơn hàng mỗi ngày</li>
            </div>
          </div>

          <br />
          <br />
          <div className="flex self-start">
            <img
              src="./img/advantages-icon1.png"
              className="w-14 h-fit mr-4"
              alt="img"
            />
            <div>
              <h5 className="text-primary font-bold mb-2">
                CHỦ HÀNG TÌM XE GIÁ RẺ
              </h5>
              <li>Giúp xử lý hàng vận chuyển với giá cước rẻ</li>
              <li>Kết nối trực tiếp với hàng chục ngàn chủ xe</li>
              <li>Tạo nhanh đơn hàng chỉ trong vài giây</li>
            </div>
          </div>

          <br />
          <br />
          <div className="flex self-start">
            <img
              src="./img/advantages-icon3.png"
              className="w-14 h-fit mr-4"
              alt="img"
            />
            <div>
              <h5 className="text-primary font-bold mb-2">
                HOÀN TOÀN MIỄN PHÍ
              </h5>
              <li>
                Sân chơi SÀN VẬN TẢI hoàn toàn miễn phí với cả Chủ hàng và Chủ
                xe, không tốn bất cứ chi phí nào
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MwaAdvantages;
