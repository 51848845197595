import { CiDeliveryTruck } from "react-icons/ci";
import { MwaCreateOrderForm } from "../export";

const MwaIntroduction = () => {
  return (
    <>
      <div
        data-section
        id="introduction"
        className="lg:bg-cover bg-contain bg-no-repeat bg-blue-900 w-full lg:h-screen h-fit"
        style={{
          backgroundImage: "url('img/banner.png')",
        }}
      >
        <div className="lg:h-full grid lg:grid-cols-6 grid-cols-1 gap-4">
          <div className="lg:col-span-3">
            <div className="lg:h-full flex flex-col justify-center px-10 lg:mt-0 mt-28">
              <h1 className="text-white text-7xl font-bold mb-5">
                SÀN VẬN TẢI
              </h1>
              <h3 className="text-white text-5xl font-bold">Xe tìm hàng</h3>
              <h3 className="text-white text-5xl font-bold">Hàng tìm xe</h3>
              <h6 className="text-slate-200 mt-10">
                Với SÀN VẬN TẢI, việc tìm kiếm hàng hóa của Chủ Xe và tìm xe phù
                hợp của Chủ Hàng sẽ diễn ra nhanh chóng, đơn giản và hiệu quả
                hơn bất kỳ nơi đâu.
              </h6>
            </div>
          </div>
          <div className="lg:col-end-7 lg:col-span-2 lg:block hidden self-end pb-10 pr-10">
            <MwaCreateOrderForm />
          </div>
          <div className="lg:hidden p-10">
            <a
              href="#create-order-form-below-lg-devices"
              className="flex items-center w-fit rounded-full bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700 hover:text-white duration-300"
            >
              <CiDeliveryTruck className="mr-2 text-2xl" /> Tìm xe cấp tốc
            </a>
          </div>
        </div>
      </div>

      <div className="grid lg:grid-cols-2 gap-4 bg-white">
        <div>
          <div className="h-full flex flex-col justify-center items-center p-10">
            <h2 className="text-primary text-4xl font-bold">
              SÀN VẬN TẢI là gì?
            </h2>
            <img
              src="./img/truck-line-break.png"
              className="w-fit py-4"
              alt="img"
            />
            <h6 className="text-justify">
              Với SÀN VẬN TẢI, việc tìm kiếm hàng hóa của Chủ Xe và tìm xe phù
              hợp của Chủ Hàng sẽ diễn ra nhanh chóng, đơn giản và hiệu quả hơn
              bất kỳ nơi đâu.
            </h6>
            <br />
            <h6 className="text-justify">
              SÀN VẬN TẢI biết chủ hàng có rất nhiều công cụ để đăng đơn tìm xe
              vận chuyển nhưng cách dễ dàng nhất là sử dụng ứng dụng được thiết
              kế riêng cho những nhu cầu vận tải hàng hóa. Bạn có thể dễ dàng
              quản lý, sao chép, thiết lập trạng thái đơn hàng và quan trọng hơn
              hết một khi đơn hàng được đăng thì ngay lập tức hàng tá đầu xe
              đang sử dụng app sẵn sàng phục vụ bạn.{" "}
            </h6>
            <br />
            <h6 className="text-justify">
              Với SÀN VẬN TẢI, Chủ Hàng chỉ cần đăng tin tìm xe qua các kênh App
              Sàn Vận Tải,web sanvantai.vn hoặc đăng tin trực tiếp bằng Zalo
              thông qua Zalo Mini App của Sàn Vận Tải.
            </h6>
          </div>
        </div>
        <div className="p-10 lg:w-3/4 m-auto">
          <img src="./img/banner-introduction.png" alt="banner" />
        </div>
      </div>
    </>
  );
};

export default MwaIntroduction;
