import { useState } from "react";
import { BiHeadphone } from "react-icons/bi";
import { FaXmark } from "react-icons/fa6";
import { TiThMenu } from "react-icons/ti";
import "./mwa_navbar.scss";

const MwaNavbar = () => {
  const navbarItems = [
    { name: "Giới thiệu", href: "#introduction" },
    { name: "Lợi ích", href: "#advantages" },
    { name: "Đăng ký", href: "#register" },
    { name: "Hướng dẫn", href: "#guide" },
    { name: "Hỏi đáp", href: "#qna" },
  ];

  const [openNavbar, setOpenNavbar] = useState(false);

  return (
    <div
      id="nav-bar"
      className="shadow-md w-full fixed top-0 left-0"
      // style={{ backgroundImage: 'url("./img/nav-bar-bg.png")' }}
    >
      <div className="lg:flex lg:flex-row lg:justify-between lg:items-center flex-col items-start justify-star py-2 lg:px-10 px-7">
        <a href="/" className="flex">
          <img
            src="./logo.png"
            className="w-12 rounded-md"
            alt="logo"
            style={{
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          />
          <div className="flex flex-col uppercase text-white ml-2 font-bold">
            <h6>Xe tìm hàng</h6>
            <h6>Hàng tìm xe</h6>
          </div>
        </a>
        <button
          className="lg:hidden text-3xl text-secondary absolute right-5 top-5"
          onClick={() => setOpenNavbar(!openNavbar)}
        >
          {openNavbar ? <FaXmark /> : <TiThMenu />}
        </button>
        <ul
          className={`lg:flex lg:items-center lg:static lg:w-auto lg:z-auto lg:pl-0 lg:pb-0 absolute z-[-1] left-0 w-full pl-9 pb-5 transition-all duration-500 ease-in 
        ${openNavbar ? "opacity-100" : "top-[-50vh]"} lg:opacity-100 opacity-0`}
        >
          {navbarItems.map((item) => (
            <li
              key={item.name}
              className="lg:ml-8 lg:my-0 my-7 text-base text-white hover:text-secondary duration-500"
            >
              <a href={item.href} onClick={() => setOpenNavbar(false)}>
                {item.name.toUpperCase()}
              </a>
            </li>
          ))}
          <a
            href="tel:+84909293228"
            className="lg:ml-8 w-fit flex items-center rounded-full bg-white px-4 py-2 font-bold hover:bg-secondary hover:text-white duration-300"
          >
            <BiHeadphone className="mr-2" /> Hotline hỗ trợ
          </a>
        </ul>
      </div>
    </div>
  );
};

export default MwaNavbar;
