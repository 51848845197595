const MwaFooter = () => {
  return (
    <footer className="text-sm text-white">
      <div className="bg-primary p-8 flex flex-col items-center">
        <h3 className="text-2xl font-bold">
          CÔNG TY TNHH CÔNG NGHỆ PyCOM SOLUTIONS
        </h3>
        <h6>© Powered By PyCOM Solutions  All rights reserved</h6>
        <span>All rights reserved</span>
        <div className="border-b-2 border-white w-full my-4"></div>
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 w-full">
          <div>
            <span className="font-semibold text-lg">Liên hệ</span>
            <div className="text-xs">
              <li className="py-1">
                Địa chỉ: 973 Phan Văn Trị, Phường 7, Gò Vấp, Hồ Chí Minh
              </li>
              <li className="py-1">Mã số thuế: 0317800291</li>
              <li className="py-1">
                Email: <a href="mailto:info@pycom.vn">info@pycom.vn</a>
              </li>
              <li className="py-1">
                Hotline: <a href="tel:+84909293228">0909 293 228 (Mr Hoàng)</a>
              </li>
            </div>
          </div>
          <div>
            <span className="font-semibold text-lg">Sản phẩm</span>
            <div className="text-xs">
              <li className="py-1">
                <a
                  href="https://pycom.vn/giai-phap-van-tai-tms"
                  target="_blank"
                  rel="noreferrer"
                >
                  Hệ thống Quản lý vận tải Pycom TMS
                </a>
              </li>
              <li className="py-1">
                <a
                  href="https://pycom.vn/giai-phap-phan-phoi-dms"
                  target="_blank"
                  rel="noreferrer"
                >
                  Hệ thống quản lý phân phối Pycom DMS
                </a>
              </li>
              <li className="py-1">
                <a
                  href="https://pycom.vn/giai-phap-zalo-apps"
                  target="_blank"
                  rel="noreferrer"
                >
                  Phát triển dịch vụ Zalo mini app
                </a>
              </li>
            </div>
          </div>
          <div>
            <span className="font-semibold text-lg">Danh mục</span>
            <div className="text-xs">
              <li className="py-1">
                <a href="https://pycom.vn" target="_blank" rel="noreferrer">
                  Giới thiệu
                </a>
              </li>
              <li className="py-1">
                <a
                  href="https://pycom.vn/chinh-sach-bao-mat"
                  target="_blank"
                  rel="noreferrer"
                >
                  Chính sách bảo mật
                </a>
              </li>
            </div>
          </div>
        </div>
      </div>
      <h5 className="bg-orange-800 text-center p-2">
        Bản Quyền 2023 © Công Nghệ PyCOM SOLUTIONS
      </h5>
    </footer>
  );
};

export default MwaFooter;
