import { FaAppStore } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";

const MwaGuide = () => {
  return (
    <div data-section id="guide" className="bg-stone-100">
      <div className="order-1 h-full flex flex-col justify-center items-center p-10">
        <h2 className="text-primary text-center text-4xl font-bold">
          CÀI APP NHANH CHÓNG
        </h2>
        <img
          src="./img/truck-line-break.png"
          className="w-fit py-4"
          alt="img"
        />
        <h6 className="text-primary">
          Tải app sàn vận tải hàng tìm xe - xe tìm hàng
        </h6>
        <h6 className="text-primary">Quét mã QR để tải APP</h6>
        <div className="flex lg:flex-row flex-col items-center">
          <div className="lg:order-1 order-2 flex flex-col items-stretch lg:m-0 mt-3">
            <a
              href="https://apps.apple.com/us/app/s%C3%A0n-v%E1%BA%ADn-t%E1%BA%A3i/id6473283920"
              target="_blank"
              rel="noreferrer"
              className=" flex items-center w-full rounded bg-primary px-4 py-2 font-bold text-white hover:bg-primaryDark hover:text-white duration-300"
            >
              <FaAppStore className="mr-2 text-2xl" />
              <div>
                <div className="text-xs font-thin">Get it on</div>
                <div className="text-sm">APP STORE</div>
              </div>
            </a>
            <br />
            <a
              href="https://play.google.com/store/apps/details?id=com.pycom.transport_exchange"
              target="_blank"
              rel="noreferrer"
              className="flex items-center w-full rounded bg-primary px-4 py-2 font-bold text-white hover:bg-primaryDark hover:text-white duration-300"
            >
              <IoLogoGooglePlaystore className="mr-2 text-2xl" />
              <div>
                <div className="text-xs font-thin">Download on the</div>
                <div className="text-sm">Google Play</div>
              </div>
            </a>
          </div>
          <img
            src="./img/app-qr-code.png"
            className="w-50 p-4 lg:order-2 order-1"
            alt="img"
          />
        </div>
      </div>
    </div>
  );
};

export default MwaGuide;
