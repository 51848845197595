const MwaRegister = () => {
  return (
    <div data-section id="register" className="bg-white">
      <div className="grid lg:grid-cols-3 gap-4">
        <div className="order-1 h-full flex flex-col justify-center items-center p-10">
          <h1 className="text-primary text-center text-4xl font-bold">
            CHỦ HÀNG KHÔNG CẦN PHẢI ĐĂNG KÝ
          </h1>
          <img
            src="./img/truck-line-break.png"
            className="w-fit py-4"
            alt="img"
          />
          <p className="text-justify">
            Với SÀN VẬN TẢI, Chủ Hàng chỉ cần đăng tin tìm xe qua các kênh App
            Sàn Vận Tải,web sanvantai.vn hoặc đăng tin trực tiếp bằng Zalo thông
            qua Zalo Mini App của Sàn Vận Tải.
          </p>
        </div>

        <div className="lg:order-2 order-3 p-10 w-full m-auto">
          <img src="./img/register-screenshot.png" alt="banner" />
        </div>

        <div className="lg:order-3 order-2 h-full flex flex-col justify-center items-center p-10">
          <h1 className="text-primary text-center text-4xl font-bold">
            CHỦ XE ĐĂNG KÝ
          </h1>
          <img
            src="./img/truck-line-break.png"
            className="w-fit py-4"
            alt="img"
          />
          <p className="text-justify">
            Chủ Xe chỉ cần tải app SÀN VẬN TẢI trên CHPLAY hoặc App Store, sau
            đó đăng ký bằng cách cung cấp cho chúng tôi thông tin xe và thông
            tin tuyến vận chuyển, đơn hàng theo tuyến sẽ tự động đề xuất cho Chủ
            Xe và gửi thông báo trên App SÀN VẬN TẢI.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MwaRegister;
