import { useState } from "react";
import Collapsible from "react-collapsible";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const MwaQna = () => {
  const data = [
    {
      question: "App SÀN VẬN TẢI dành cho những ai?",
      answer:
        "- Sàn vận tải là mạng xã hội giao lưu vận tải giữa Chủ hàng và Nhà vận tải hoặc Tài xế.",
    },
    {
      question: "Dòng điện thoại nào có thể tải được App SÀN VẬN TẢI?",
      answer:
        "- Ứng dụng Sàn vận tải hỗ trợ với tất cả các loại thiết bị trên cả IOS và Android, Máy tính cá nhân, máy tính bảng.",
    },
    {
      question: "Chủ Xe tìm hàng qua App có mất phí dịch vụ không?",
      answer: "- Sàn vận tải hoàn toàn miễn phí cho cả Chủ hàng Và Chủ xe.",
    },
    {
      question: "Mẹo sử dụng App SÀN VẬN TẢI một cách hiệu quả?",
      answer: `- Chủ hàng chỉ cần 1 thao tác đơn giản như đăng 1 bài lên Zalo hoặc Facebook có thể tiếp cận đến Tài xế nhanh chóng.\n
        - Tài xế không cần đăng ký, chỉ cần đăng nhập bằng số điện thoại có thể xem danh sách các Đơn hàng cần vận chuyển.`,
    },
    {
      question: "Cách xem đơn hàng và liên hệ trực tiếp với Chủ Hàng?",
      answer:
        "- Tài xế chỉ cần vào Danh sách đơn hàng mới đăng để tìm kiếm đơn hàng phù hợp với Tuyến đường và tải trọng phù hợp.",
    },
    {
      question: "Cách cấu hình tuyến để nhận thông báo các đơn hàng phù hợp?",
      answer:
        "- Sàn Vận Tải tự động lọc Tuyến phù hợp với từng Tài xế để thông báo đơn hàng phù hợp nhất.",
    },
  ];

  return (
    <div
      data-section
      id="qna"
      className="bg-white p-10 flex flex-col items-center"
    >
      <h2 className="text-primary text-4xl font-bold">
        Các câu hỏi thường gặp
      </h2>
      <img src="./img/truck-line-break.png" className="w-fit py-4" alt="img" />
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
        {data.map((item, index) => (
          <div key={index}>
            {_Collapsible({ question: item.question, answer: item.answer })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MwaQna;

const _Collapsible = ({
  question,
  answer,
}: {
  question: String;
  answer: String;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Collapsible
      open={open}
      trigger={
        <div
          className="bg-primary rounded-full px-4 py-2 text-white text-sm flex justify-between cursor-pointer"
          onClick={() => setOpen(!open)}
        >
          <span>{question}</span>
          {open ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      }
    >
      <span className="p-4 text-sm text-justify">{answer}</span>
    </Collapsible>
  );
};
