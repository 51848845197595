import { SnackbarProvider } from "notistack";
import {
  MwaAdvantages,
  MwaCreateOrderForm,
  MwaFooter,
  MwaGuide,
  MwaIntroduction,
  MwaNavbar,
  MwaQna,
  MwaRegister,
} from "../components/export";
import "./App.scss";

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <MwaNavbar />
      <MwaIntroduction />
      <MwaAdvantages />
      <MwaRegister />
      <MwaGuide />
      <div id="create-order-form-below-lg-devices" className="lg:hidden block">
        <MwaCreateOrderForm />
      </div>
      <MwaQna />
      <MwaFooter />
    </SnackbarProvider>
  );
}

export default App;
